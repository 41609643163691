// import font
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
//import icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@ng-select/ng-select/themes/default.theme.css";

//Custom properties
:root {
  box-sizing: border-box;
}

body {
  background-color: rgb(248, 249, 254);
}

* {
  font-family: "Cabin", sans-serif;
}

.reading-block {
  display: none !important;
  color: transparent !important;
  font-size: 0px !important;
}

.glass {
  background: rgba(199, 199, 199, 0.13);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.6px);
  -webkit-backdrop-filter: blur(1.6px);
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.3) 2px 5px 10px;
}

.animate-200 {
  transition: 200ms ease-in-out;
}

.animate-250 {
  transition: 250ms ease-in-out;
}

.animate-500 {
  transition: 500ms ease-in-out;
}

.animate-1000 {
  transition: 1000ms ease-in-out;
}

.text-ready {
  color: #006db5;
}

// @import "~mdb-angular-ui-kit/assets/scss/mdb.scss/bootstrap/functions";
// @import "~mdb-angular-ui-kit/assets/scss/mdb.scss/free/functions";

// @import "~mdb-angular-ui-kit/assets/scss/mdb./free/variables";
// @import "~mdb-angular-ui-kit/assets/scss/mdb.scss/bootstrap/utilities";

$breadcrumb-divider: quote("");

$primary: rgb(79, 79, 79);
$secondary: rgb(119, 124, 153);
$light: rgb(248, 249, 254);
$dark: rgb(125, 125, 125);
$danger: #c72020;
$success: rgb(45, 106, 64);
$info: rgb(0, 109, 181);
// $success: rgb(38, 141, 38);

$ready: #006db5;
$notReady: #ed7226;

@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

$custom-colors: (
  ready: #006db5,
  notReady: #ed7226,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
// map.set($theme-colors, "ready": $ready)
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

.select.focused {
  & ~ .form-notch .form-notch-leading {
    border-top: 2px solid $primary !important;
    border-bottom: 2px solid $primary !important;
    border-left: 2px solid $primary !important;
    transition: all 0.2s linear;
  }

  & ~ .form-notch .form-notch-middle {
    border-bottom: 2px solid;
    border-color: $primary !important;
    transition: all 0.2s linear;
  }

  & ~ .form-notch .form-notch-trailing {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: $primary !important;
    transition: all 0.2s linear;
  }
}

.ng-select {
  width: 250px;
}

.ng-select .ng-select-container {
  border: 2px solid rgba(79, 79, 79, 0.5);
  background-color: transparent;
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: transparent;
}

.ng-dropdown-panel-items {
  z-index: 9999 !important;
}
.ng-dropdown-panel {
  z-index: 9999 !important;
  border: 2px solid $primary;
}

.ng-select.ng-select-opened > .ng-select-container {
  border: 2px solid $primary;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $light;
  color: $primary;
}

.b-list {
  font-weight: bold;
}

.vector-map-tooltip {
  background-color: white;
  border-radius: 4px;
  padding-inline: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.07),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.select.focused .select-arrow {
  color: $primary;
}

.page-item.disabled .page-link {
  background-color: transparent;
  border-color: transparent;
}

.vector-map-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem;
  .btn-floating {
    margin-inline: 0.5rem;
  }
}

.form-control {
  border: 2px solid rgba(79, 79, 79, 0.5);
  background-color: transparent;
}

.form-control:focus {
  border: 2px solid $primary;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(248, 249, 254, 0.6);
  background-color: transparent;
}

.btn-close {
  color: white;
}

.accordion-body {
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.modal-calendar {
  height: 95vh;
}

.sidenav-width {
  width: 17.5rem;
}

.bg-error {
  background-color: #c7202044;
}

.ng-dropdown-panel-items {
  max-height: 10rem !important;
}

.modal-body-height {
  height: 80vh;
  @media (max-height: 840px) {
    height: 70vh;
  }
}

.reading-label {
  font-size: 16px !important;
}
